import { useState } from 'react'
import { z } from 'zod'

export enum LocalStorageKey {
  ADMIN_DATA = 'adminData',
  ADMIN_PRICE_RECOMMENDATIONS_TAB = 'adminPriceRecommendationsTab',
  ADMIN_WRITE_MODE = 'adminWriteMode',
  CONVERSION_WINDOW_DAYS = 'conversionWindowDays',
  PORTFOLIO_SID = 'portfolioSid',
  PRICING_VIEW_GROUPING_LEVEL = 'pricingViewGroupingLevel',
  PROPERTY_AMENITIES_TAB = 'propertyAmenitiesTab',
  RENEWALS_DATE_RANGE = 'renewalsDateRange', // YYYY-MM-DD,YYYY-MM-DD
  RENEWAL_REQUESTS_ASSIGNEE_TAB = 'renewalRequestsAssigneeTab',
  RENEWAL_REQUESTS_STATE_TAB = 'renewalRequestsStateTab',
  RENEWAL_SUMMARY_DATE_GROUPING = 'renewalSummaryDateGrouping',
}

const localStorageKeyToSchema = {
  [LocalStorageKey.ADMIN_DATA]: z.string(),
  [LocalStorageKey.ADMIN_PRICE_RECOMMENDATIONS_TAB]: z.string(),
  [LocalStorageKey.ADMIN_WRITE_MODE]: z.string(),
  [LocalStorageKey.CONVERSION_WINDOW_DAYS]: z.number(),
  [LocalStorageKey.PORTFOLIO_SID]: z.string(),
  [LocalStorageKey.PRICING_VIEW_GROUPING_LEVEL]: z.string(),
  [LocalStorageKey.PROPERTY_AMENITIES_TAB]: z.string(),
  [LocalStorageKey.RENEWALS_DATE_RANGE]: z.string(),
  [LocalStorageKey.RENEWAL_REQUESTS_ASSIGNEE_TAB]: z.string(),
  [LocalStorageKey.RENEWAL_REQUESTS_STATE_TAB]: z.string(),
  [LocalStorageKey.RENEWAL_SUMMARY_DATE_GROUPING]: z.string(),
} as const

export function useLocalStorage<K extends LocalStorageKey>(
  key: K,
  defaultValue?: z.infer<(typeof localStorageKeyToSchema)[K]>
): [
  z.infer<(typeof localStorageKeyToSchema)[K]> | null,
  (value: z.infer<(typeof localStorageKeyToSchema)[K]>) => void,
] {
  const [value, setValue] = useState<z.infer<(typeof localStorageKeyToSchema)[K]> | null>(() => {
    const item = localStorage.getItem(key)
    if (item === null) return defaultValue ?? null

    try {
      const schema = localStorageKeyToSchema[key]
      const parsedValue = schema._def.typeName === 'ZodNumber' ? parseFloat(item) : item
      return schema.parse(parsedValue)
    } catch (error) {
      console.error(`[useLocalStorage] validation failed for ${key}`, error)
      return null
    }
  })

  const setLocalStorageValue = (value: z.infer<(typeof localStorageKeyToSchema)[K]>) => {
    localStorage.setItem(key, typeof value === 'number' ? value.toString() : value)
    setValue(value)
  }

  return [value, setLocalStorageValue]
}
