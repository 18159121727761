import { stringify } from 'querystring'

import { ReportType, UnitGroup } from '~/graphql/generated/query.types'

export enum PropertiesRoute {
  summary = 'summary',
  pricing = 'pricing',
  units = 'units',
  expirations = 'expirations',
  comps = 'comps',
  specials = 'specials',
  amenities = 'amenities',
  settings = 'settings',
}

type WebRoute = {
  pathname: string
  query: Record<string, string>
}

export function generatePropertiesRoute(
  route: PropertiesRoute.summary,
  options: { sid: string }
): WebRoute
export function generatePropertiesRoute(
  route: PropertiesRoute.pricing,
  options: { sid: string; group?: UnitGroup }
): WebRoute
export function generatePropertiesRoute(
  route: PropertiesRoute.units,
  options: { sid: string; unitSid?: string }
): WebRoute
export function generatePropertiesRoute(
  route: PropertiesRoute.expirations,
  options: { sid: string }
): WebRoute
export function generatePropertiesRoute(
  route: PropertiesRoute.comps,
  options: { sid: string }
): WebRoute
export function generatePropertiesRoute(
  route: PropertiesRoute.specials,
  options: { sid: string }
): WebRoute
export function generatePropertiesRoute(
  route: PropertiesRoute.amenities,
  options: { sid: string }
): WebRoute
export function generatePropertiesRoute(
  route: PropertiesRoute.settings,
  options: { sid: string }
): WebRoute
export function generatePropertiesRoute(
  route: PropertiesRoute,
  options: { sid: string }
): WebRoute {
  return {
    pathname: 'properties',
    query: { ...options, view: route },
  }
}

export enum RenewalsRoute {
  expirations = 'expirations',
  monthToMonth = 'monthToMonth',
  batches = 'batches',
  summary = 'summary',
}

export function generateRenewalsRoute(
  route: RenewalsRoute.summary,
  options: { sid?: string; propertySid?: string }
): WebRoute
export function generateRenewalsRoute(
  route: RenewalsRoute.expirations,
  options: { sid?: string }
): WebRoute
export function generateRenewalsRoute(
  route: RenewalsRoute.batches,
  options: { sid?: string; renewalRequestSid?: string; activeTab?: string }
): WebRoute
export function generateRenewalsRoute(
  route: RenewalsRoute,
  options:
    | { sid?: string }
    | { sid?: string; propertySid?: string }
    | { sid?: string; renewalRequestSid?: string }
): WebRoute {
  return {
    pathname: 'renewals',
    query: { ...options, view: route },
  }
}

export enum ReportsRoute {
  reports = 'reports',
  scheduled = 'scheduled',
  history = 'history',
  preview = 'preview',
}

export function generateReportsRoute(
  route: ReportsRoute.reports,
  options: { reportType?: undefined; reportSid?: string; sid?: string }
): WebRoute
export function generateReportsRoute(
  route: ReportsRoute.history,
  options: { reportType?: undefined; reportSid?: string; sid?: string }
): WebRoute
export function generateReportsRoute(
  route: ReportsRoute.scheduled,
  options: { reportType?: undefined; reportSid?: string; sid?: string }
): WebRoute
export function generateReportsRoute(
  route: ReportsRoute.preview,
  options: { reportType: ReportType; reportSid: string; sid?: string }
): WebRoute
export function generateReportsRoute(
  route: ReportsRoute,
  options: { reportType?: ReportType; reportSid?: string; sid?: string }
): WebRoute {
  return {
    pathname: 'reports',
    query: { ...options, reportsView: route },
  }
}

export enum CompanyRoute {
  information = 'information',
  properties = 'properties',
  portfolios = 'portfolios',
  users = 'users',
}
export function generateCompanyRoute(route: CompanyRoute): WebRoute {
  return {
    pathname: 'company',
    query: { view: route },
  }
}

export enum AdminRoute {
  users = 'users',
  featureFlags = 'featureFlags',
  jobs = 'jobs',
  jobQueues = 'jobQueues',
  alerts = 'alerts',
  comps = 'comps',
  firms = 'firms',
  properties = 'properties',
  metrics = 'metrics',
  models = 'models',
  recommendations = 'recommendations',
  graphql = 'graphql',
}
export function generateAdminRoute(
  route: AdminRoute,
  options: {
    firmSid?: string
  } = {}
): WebRoute {
  return {
    pathname: 'admin',
    query: { ...options, view: route },
  }
}

export function makeURLStringFromURLObject(urlObject: WebRoute) {
  // Filter out properties with null or undefined values
  const filteredQuery = Object.entries(urlObject.query)
    .filter(([_key, value]) => value !== null && value !== undefined)
    .reduce(
      (obj, [key, value]) => {
        obj[key] = value as any
        return obj
      },
      {} as Record<string, string>
    )

  // Stringify the result
  const query = stringify(filteredQuery)
  return urlObject.pathname + (query ? '?' + query : '')
}
