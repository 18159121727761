import dayjs, { ManipulateType } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { checkOk } from './check'
import { DayOfWeek } from './constants'
dayjs.extend(tz)
dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(dayOfYear)
dayjs.extend(relativeTime)

export const UTC = 'UTC'
export const LA = 'America/Los_Angeles'
export const MT = 'US/Mountain'
type DateUnitTypeShort = 'd' | 'D' | 'M' | 'y' | 'h'
type DateUnitTypeLong = 'day' | 'month' | 'year' | 'hour'
type DateUnitTypeLongPlural = 'days' | 'months' | 'years' | 'hours'
export type DateManipulateType = DateUnitTypeShort | DateUnitTypeLong | DateUnitTypeLongPlural
export class LocalDate {
  /**
   * Local date in ISO format (YYYY-MM-DD)
   */
  private localDate: string

  private constructor(localDate: string) {
    this.localDate = localDate
  }

  static today(timezone: string) {
    // Today is not aware of timezone so it needs to be passed in.
    return new LocalDate(dayjs().tz(timezone).format('YYYY-MM-DD'))
  }

  static todayLA() {
    return LocalDate.today('America/Los_Angeles')
  }

  static todaySystem() {
    return new LocalDate(dayjs().format('YYYY-MM-DD'))
  }

  /**
   * JS's Date is implicitly an instant in time, so when constructing it from a string, it will
   * do weird things like
   * 1) if the string is 10-digit date, it will assume it's in UTC,
   * 2) if the string is YYYY-MM-DDTHH:mm:ss, it will assume it's in local time,
   * 3) if the string is YYYY-MM-DDTHH:mm:ssZ, it will assume it's in UTC.
   *
   * This method assumes the user has the correct Date object in mind and will only convert
   * the instant to the local date in the target timezone.
   */
  static fromDate(date: Date, timezone: string) {
    return new LocalDate(dayjs(date).tz(timezone).format('YYYY-MM-DD'))
  }

  static from(localDate: string) {
    checkOk(dayjs(localDate, 'YYYY-MM-DD', true).isValid(), `invalid local date: ${localDate}`)
    return new LocalDate(localDate)
  }

  static fromLocalDatetime(datetime: string, fromTz: string, toTz: string) {
    checkOk(guessLocalOrZoned(datetime) === 'local', `invalid local datetime: ${datetime}`)
    return new LocalDate(dayjs.tz(datetime, fromTz).tz(toTz).format('YYYY-MM-DD'))
  }

  static fromZonedDatetime(datetime: string, toTz?: string) {
    checkOk(guessLocalOrZoned(datetime) === 'zoned', `invalid zoned datetime: ${datetime}`)
    return new LocalDate(dayjs(datetime).tz(toTz).format('YYYY-MM-DD'))
  }

  plus(amount: number, unit: DateManipulateType) {
    return new LocalDate(dayjs(this.localDate).add(amount, unit).format('YYYY-MM-DD'))
  }

  minus(amount: number, unit: DateManipulateType) {
    return new LocalDate(dayjs(this.localDate).subtract(amount, unit).format('YYYY-MM-DD'))
  }

  startOf(unit: DateManipulateType) {
    return new LocalDate(dayjs(this.localDate).startOf(unit).format('YYYY-MM-DD'))
  }

  endOf(unit: DateManipulateType) {
    return new LocalDate(dayjs(this.localDate).endOf(unit).format('YYYY-MM-DD'))
  }

  dayOfMonth() {
    return dayjs(this.localDate).date()
  }

  diff(otherDate: string, unit: DateManipulateType, float?: boolean) {
    return dayjs(this.localDate).diff(otherDate, unit, float)
  }

  isBefore(otherDate: string) {
    return dayjs(this.localDate).isBefore(otherDate, 'day')
  }

  isOnOrBefore(otherDate: string) {
    return !dayjs(this.localDate).isAfter(otherDate, 'day')
  }

  isAfter(otherDate: string) {
    return dayjs(this.localDate).isAfter(otherDate, 'day')
  }

  isOnOrAfter(otherDate: string) {
    return !dayjs(this.localDate).isBefore(otherDate, 'day')
  }

  isBetweenInclusive(start: string, end: string) {
    return this.isOnOrAfter(start) && this.isOnOrBefore(end)
  }

  monthNumberZeroIndexed() {
    return dayjs(this.localDate).month()
  }

  month(verbose = false) {
    const month = dayjs(this.localDate).month()
    switch (month) {
      case 0:
        return verbose ? 'January' : 'Jan'
      case 1:
        return verbose ? 'February' : 'Feb'
      case 2:
        return verbose ? 'March' : 'Mar'
      case 3:
        return verbose ? 'April' : 'Apr'
      case 4:
        return 'May'
      case 5:
        return verbose ? 'June' : 'Jun'
      case 6:
        return verbose ? 'July' : 'Jul'
      case 7:
        return verbose ? 'August' : 'Aug'
      case 8:
        return verbose ? 'September' : 'Sep'
      case 9:
        return verbose ? 'October' : 'Oct'
      case 10:
        return verbose ? 'November' : 'Nov'
      case 11:
        return verbose ? 'December' : 'Dec'
      default:
        throw new Error(`unexpected month for time ${this.localDate}: ${month}`)
    }
  }

  daysInMonth() {
    return dayjs(this.localDate).daysInMonth()
  }

  dayOfWeekNumber(): number {
    return dayjs(this.localDate).day()
  }

  dayOfWeek(): DayOfWeek {
    const day = dayjs(this.localDate).day()
    switch (day) {
      case 0:
        return 'Sunday'
      case 1:
        return 'Monday'
      case 2:
        return 'Tuesday'
      case 3:
        return 'Wednesday'
      case 4:
        return 'Thursday'
      case 5:
        return 'Friday'
      case 6:
        return 'Saturday'
      default:
        throw new Error(`unexpected day of week for time ${this.localDate}: ${day}`)
    }
  }

  dayOfYear() {
    return dayjs(this.localDate).dayOfYear()
  }

  year() {
    return dayjs(this.localDate).year()
  }

  getLastSunday(): LocalDate {
    const dayOfWeek = dayjs(this.localDate).day()
    return this.minus(dayOfWeek, 'day')
  }

  format(format?: string) {
    if (format) {
      return dayjs(this.localDate).format(format)
    } else {
      return this.localDate
    }
  }

  formatInt32() {
    return dayjs(this.localDate).format('YYYYMMDD')
  }

  toString() {
    return this.localDate
  }

  toJSON() {
    return this.localDate
  }

  equals(other: LocalDate) {
    return this.localDate === other.localDate
  }

  toMidnightDate(timezone: string): Date {
    return dayjs.tz(this.localDate, timezone).toDate()
  }

  toLocalMidnightDate(): Date {
    return dayjs(this.localDate).toDate()
  }

  formatRelativeTo(other: string | Date, excludeModifier?: boolean) {
    return dayjs(this.localDate).to(other, excludeModifier)
  }

  formatRelativeFrom(other: string | Date, excludeModifier?: boolean) {
    return dayjs(other).to(this.localDate, excludeModifier)
  }
}

export function nowMinus(value: number, unit?: ManipulateType): Date {
  return dayjs().subtract(value, unit).toDate()
}

export function nowPlus(value: number, unit?: ManipulateType): Date {
  return dayjs().add(value, unit).toDate()
}

export function dateMinus(date: Date, value: number, unit: ManipulateType) {
  return dayjs(date).subtract(value, unit).toDate()
}

export function datePlus(date: Date, value: number, unit: ManipulateType) {
  return dayjs(date).add(value, unit).toDate()
}

export function intervalToDuration(start: Date, end: Date): string {
  const diff = end.getTime() - start.getTime()
  checkOk(diff >= 0, 'end time should be after start time')
  return millisecondsToDuration(diff)
}

export function millisecondsToDuration(milliseconds: number): string {
  checkOk(milliseconds >= 0, 'milliseconds should be positive')
  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const daysString = days > 0 ? `${days}d ` : ''
  const hoursString = hours % 24 > 0 ? `${hours % 24}h ` : ''
  const minutesString = minutes % 60 > 0 ? `${minutes % 60}m ` : ''
  const secondsString = seconds % 60 > 0 ? `${seconds % 60}s` : ''
  return `${daysString}${hoursString}${minutesString}${secondsString}`
}

export function secondsBeforeNow(timeString: string) {
  const time = new Date(`${timeString}`).getTime()
  const today = new Date().getTime()
  return (today - time) / 1000
}

export function daysBeforeNow(timeString: string) {
  const date = new Date(timeString)
  const today = new Date()
  const utc1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  const utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
  const msPerDay = 1000 * 60 * 60 * 24
  return Math.floor((utc2 - utc1) / msPerDay)
}

export function parseFromTz(date: string, timezone: string): Date {
  return dayjs.tz(date, timezone).toDate()
}

export function parseFromUTC(date: string): Date {
  return dayjs.utc(date).toDate()
}

export function formatRelative(from: string | Date, to: string | Date, excludeModifier?: boolean) {
  return dayjs(from).to(to, excludeModifier)
}

export function formatInTz(date: Date, timezone: string, format: string) {
  return dayjs(date).tz(timezone).format(format)
}

export function guessLocalOrZoned(date: string) {
  if (
    date.includes('Z') ||
    date.includes('+') ||
    (date.includes('T') && date.split('T')[1]!.includes('-'))
  ) {
    return 'zoned'
  } else {
    return 'local'
  }
}

export function parseLocalOrZonedDate(date: string): Date {
  const isLocal = guessLocalOrZoned(date) === 'local'
  const parsedDate = isLocal ? parseFromTz(date, LA) : new Date(date)
  return parsedDate
}

export function isDateString(date: string | undefined | null): boolean {
  return dayjs(date, 'YYYY-MM-DD', true).isValid()
}

export function generateDateRange(from: string, to: string, intervalDays = 1): string[] {
  checkOk(LocalDate.from(from).isOnOrBefore(to), 'from date should be on or before to date')
  checkOk(intervalDays > 0, 'intervalDays should be positive')
  const dates: string[] = []
  let currentDate = LocalDate.from(from)
  const endDate = LocalDate.from(to)
  while (currentDate.isOnOrBefore(endDate.toString())) {
    dates.push(currentDate.toString())
    currentDate = currentDate.plus(intervalDays, 'day')
  }
  return dates
}

/**
 * Returns a range of months in the format YYYY-MM, given a start and end date.
 */
export function generateMonthRange(
  /** YYYY-MM-DD */
  from: string,
  /** YYYY-MM-DD */
  to: string
): string[] {
  const months: string[] = []
  let currentDate = LocalDate.from(from).startOf('month')
  const toStartOfMonth = LocalDate.from(to).startOf('month')
  while (currentDate.isOnOrBefore(toStartOfMonth.toString())) {
    months.push(currentDate.format('YYYY-MM'))
    currentDate = currentDate.plus(1, 'month')
  }
  return months
}

export function safeFormatDate(date: string | undefined | null, format: string) {
  if (date && dayjs(date, 'YYYY-MM-DD', true).isValid()) {
    return LocalDate.from(date).format(format)
  }
  return ''
}

export function minDate(
  /** YYYY-MM-DD */
  date: string,
  ...otherDates: string[]
): string {
  if (otherDates.length === 0) {
    return date
  }
  return otherDates.reduce((min, current) => (current < min ? current : min), date)
}

export function maxDate(
  /** YYYY-MM-DD */
  date: string,
  ...otherDates: string[]
): string {
  if (otherDates.length === 0) {
    return date
  }
  return otherDates.reduce((max, current) => (current > max ? current : max), date)
}
