import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools'

const baseStyle = (props: StyleFunctionProps) => ({
  dialog: {
    bg: mode('white', 'gray.800')(props),
    borderRadius: 'md',
  },
})

export default {
  baseStyle,
}
